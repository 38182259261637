import { CopySvg } from "../components/dashbord/icons/icons";
import Actions from "./actions";
//////////////////////////////////////////////////////////
export const saveData = (item, data, type = "localStorage") => {
    window[type].setItem(item, JSON.stringify(data));
}

export const getData = (item, type = "localStorage") => {
    return JSON.parse(window[type].getItem(item));
}

export const checkData = (item, type = "localStorage") => {
    if (window[type].getItem(item)) return true;
    else return false;
}

export const delData = (item, type = "localStorage") => {
    window[type].removeItem(item);
}
/////////////////////////////////////////////////////////////
const BinarySearch = ({ org, target, start, end, key, callback }) => {
    const mid = Math.floor((start + end) / 2);
    if (target === org[mid][key]) return callback(org[mid], mid);
    if (end - 1 === start) return Math.abs(org[start][key] - target) > Math.abs(org[end][key] - target) ? org[end] : org[start];
    if (target > org[mid][key]) return BinarySearch({ org, target, start: mid, end, key, callback });
    if (target < org[mid][key]) return BinarySearch({ org, target, start, end: mid, key, callback });
}

export const LinearSearch = ({ org, target, start, end, key, callback }) => {
    for (var i = start; i < end; i++) {
        if (target === org[i][key]) {
            return callback(org[i], i);
        }
    }
}
/////////////////////////////////////////////////////////
export const HandelNumber = (num1, num2, type = false) => {
    let num = (num1 || 0) + (num2 || 0);
    num = type ? num : num + 1;
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num;
}
/////////////////////////////////////////////////////////
export const InsertEmoji = (emoji, Node) => {
    const [start, end] = [Node.selectionStart, Node.selectionEnd];
    Node.setRangeText(emoji, start, end);
    Node.selectionStart = Node.selectionEnd = start + emoji.length;
    Node.focus();
    Node.blur();
}
//////////////////////////////////////////////////////////////
export const copyToClipboard = (str, text, dispatch) => {
    if (window.ReactNativeWebView) {
        const message = JSON.stringify({ url: str, type: "share" });
        window.ReactNativeWebView.postMessage(message);
        return;
    }
    // if (navigator.share) {
    //     navigator.share({
    //         // text: "meetoor",
    //         title: 'Share your Link - MEETOOR',
    //         url: "",
    //         text: str
    //     }).then((e) => {
    //         console.log("then ==>", e)
    //     }).catch(() => { });
    // } 
    else {
        ///////////////////////////////////////////////////////
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        dispatch && dispatch(Actions.type("setToast", {
            text: text,
            icon: <CopySvg size="20" className="lit" />
        }));
        setTimeout(() => {
            dispatch && dispatch(Actions.type("setToast", undefined));
        }, 2000);
    }
};
//////////////////////////////////////////////////////////////
export const setMediaConstraints = ({
    rtc, deviceId
}) => {
    var videoConstraints = {};

    videoConstraints = {
        width: {
            ideal: 480
        },
        height: {
            ideal: 320
        },
        frameRate: 30
    };

    rtc.bandwidth = {
        audio: 128,  // 50 kbps
        video: 256 // 256 kbps
    };

    if (deviceId) videoConstraints.deviceId = deviceId

    // if (deviceId) {
    //     if (!!navigator.webkitGetUserMedia) {
    //         videoConstraints.optional = [{
    //             sourceId: deviceId
    //         }]
    //     }
    // }

    rtc.sdpConstraints.mandatory = {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true
    };

    rtc.mediaConstraints = {
        video: videoConstraints,
        audio: {
            echoCancellation: true,
        }
    };


}
//////////////////////////////////////////////////////////////
export const Accounts = (pass, del) => {
    let check = checkData("accounts");
    let oldData = getData("accounts");
    const data = check ? oldData : [];
    if (typeof del === "number") data.splice(del, 1);
    if (pass) data.push(pass);
    saveData("accounts", data);
    return data;
}

export const setUser = (type, data, org) => {
    org = Object.assign({}, org);
    switch (type) {
        case "set":
            return data;

        case "update":
            org[data.key] = data.val;
            return org;
        default:
            break;
    }
}
////////////////////////////////////////////////////////
export const handelSuggest = (type, data, org) => {
    org = Object.assign([], org);
    switch (type) {
        case "set":
            return data;

        case "add":
            org.push(data);
            return org.sort((a, b) => a.userid - b.userid);

        case "push":
            return [...org, ...data];

        case "update":
            org && LinearSearch({
                org: org,
                target: data.target,
                start: 0,
                end: org.length,
                key: data.key,
                callback: (target, index) => data.callback(target, index, org)
            });
            return org;

        case "delete":
            org && LinearSearch({
                org,
                target: data.target,
                start: 0,
                end: org.length,
                key: data.key,
                callback: (target, index) => data.callback(target, index, org)
            });
            return org;

        default:
            break;
    }
}
////////////////////////////////////////////////////////
export const setTitle = (type, data, org) => {
    switch (type) {
        case "set":
            return data;

        case "update":
            return data + org;
        default:
            break;
    }
}

export const setNotifyNumber = (type, data, org) => {
    switch (type) {
        case "set":
            return data;

        case "update":
            return org + data;
        default:
            break;
    }
}

const createPathServer = (server) => {
    const prt = ['80', '3478', '80', '3478', '443', '5349'];
    const trans = ["transport=udp", "transport=udp", "transport=tcp", "transport=tcp", "transport=tcp", "transport=tcp"];
    const servers = [];
    prt.forEach((pr, i) => {
        servers.push(`turn:${server}:${pr}?${trans[i]}`);
    });
    return servers;
}

export const update = (type, data, org) => {
    org = Object.assign([], org);
    switch (type) {
        case "set":
            return data ? data : org;

        case "add":
            org.unshift(data);
            return org;

        case "push":
            org.push(data);
            return org;

        case "addGroup":
            return [...org, ...data];

        case "update":
            LinearSearch({
                org: org,
                target: data.target,
                start: 0,
                end: org.length,
                key: data.key,
                callback: (target, index) => {
                    target.isRead = true;
                }
            });
            return org;

        case "updateWithCall":
            LinearSearch({
                org: org,
                target: data.target,
                start: 0,
                end: org.length,
                key: data.key,
                callback: (target, index) => {
                    // console.log("update -> target", target)
                    data.call(target, index);
                }
            });
            return org;

        case "updateAll":
            org.forEach((item) => {
                data.call(item);
            })
            return org;

        case "delete":
            LinearSearch({
                org: org,
                target: data.target,
                start: 0,
                end: org.length,
                key: data.key,
                callback: (target, index) => {
                    // console.log("update -> target", target)
                    org.splice(index, 1);
                }
            });
            return org;

        default:
            break;
    }
}
///////////////////////////////////////////////////////////
export const updateForVideo = (type, data, callback, org) => {
    org = Object.assign([], org);
    let Item;
    switch (type) {
        case "set":
            // console.log("update -> set", data)
            return data ? data : org;

        case "add":

            Item = org?.find(object => object['userid'] === data.userid);

            if (Item) {
                Item['stream'] = data.stream;
                Item['streamid'] = data.streamid;
                Item['isVideo'] = data.isVideo;
                Item['userid'] = data.userid;
                Item['extra'] = data.extra;
                callback && callback(Item);
                return org;
            } else {
                callback && callback(data);
                org.push(data);
                return org;
            }

        case "update":

            Item = org?.find(object => object['userid'] === data.userid);

            if (Item) {
                callback && callback(Item);
            }
            return org;

        case "updateManule":
            for (let i = 0; i < org.length; i++) {
                const item = org[i];
                data.call(item);
            }
            return org;

        case "update-all":
            for (let i = 0; i < org.length; i++) {
                const item = org[i];
                item[data.key] = data.val;
            }
            return org;

        case "update-all-in":
            for (let i = 0; i < org.length; i++) {
                const item = org[i];
                data.call(item[data.key]);
            }
            return org;

        case "delete":
            return org?.filter(object => {
                callback && object[data.key] === data.target && callback(object);
                return object[data.key] !== data.target;
            });

        default:
            break;
    }
}
///////////////////////////////////////////////////////////
export const updateForActive = (type, data, org) => {
    org = Object.assign([], org);
    switch (type) {
        case "set":
            // console.log("update -> set", data)
            return data ? data : org;

        case "add":
            let targetIndex = org?.findIndex(object => object['userid'] === data.userid);
            if (targetIndex >= 0) {
                org[targetIndex] = data;
                return org;
            }
            org.push(data);
            return org;

        case "delete":
            return org?.filter(object => {
                return object[data.key] !== data.target;
            });

        default:
            break;
    }
}
///////////////////////////////////////////////////////////
export const createServer = (runer) => {
    let server = 'rtc.meetoor.com';
    let order = runer.split(':');
    // call: [{ url: `stun:${server}` },
    //     {
    //         username: name,
    //         credential: run,
    //         urls: createPathServer(server)
    //     }],
    return {
        run: [{
            username: order[0],
            credential: order[1],
            urls: createPathServer(server)
        }],
        server: runer
    };
}
///////////////////////////////////////////////////////////
export const reobject = (type, data, org) => {
    org = Object.assign({}, org);
    switch (type) {
        case "add":
            org[data.key] = data.val;
            return org;

        case "update":
            if (!org[data.key]) return org;
            console.log("update", data.key)
            data.call(org[data.key]);
            return org;

        case "updateArray":
            LinearSearch({
                org: org[data.keyArray]?.members,
                target: data.target,
                start: 0,
                end: org[data.keyArray]?.members.length,
                key: data.key,
                callback: (target, index) => {
                    data.call(target, index, org[data.keyArray]?.members);
                }
            });
            return org;

        case "delete":
            delete org[data.key];
            return org;

        default:
            break;
    }
}
///////////////////////////////////////////////////////////
export const updateObj = (type, data, org) => {
    org = Object.assign({}, org);
    let found = false;
    switch (type) {
        case "set":
            org[data.key] = data.val;
            return org;

        case "update":
            org[data.key] ? org[data.key].unshift(data.val) : org[data.key] = [data.val];
            return org;

        case "getToUpdate":
            org[data.key] && data.call(org[data.key]);
            return org;

        case "add":
            console.log("add ~ data", data);
            org[data.key] ? org[data.key].push(data.val) : org[data.key] = [data.val];
            return org;

        case "addGroup":
            console.log("add ~ data", data);
            //org[data.key] ? org[data.key].push(...data.val) :
            org[data.key] = [...org[data.key], ...data.val];
            return org;

        case "pushGroup":
            console.log("pushGroup ~ data", data);
            //org[data.key] ? org[data.key].push(...data.val) :
            org[data.key] = [...data.val, ...org[data.key]];
            return org;


        case "pop":
            org[data] && org[data].pop();
            return org;

        case "updates":
            data?.keysArray?.forEach((keyArray) => {
                org[keyArray] && org[keyArray].unshift(data.val);
            });
            return org;

        case "updateWithCall":
            console.log("fsf", data, org)
            if (!org[data.keyArray]) return org;
            LinearSearch({
                org: org[data.keyArray],
                target: data.target,
                start: 0,
                end: org[data.keyArray].length,
                key: data.key,
                callback: (target, index) => {
                    // console.log("update -> target", target)
                    data.call(target, index);
                }
            });
            // org[data.keyArray].reverse()
            return org;

        case "check":
            if (!org[data.keyArray]) return org;
            let value = org[data.keyArray];
            LinearSearch({
                org: value,
                target: data.target,
                start: 0,
                end: value.length,
                key: data.key,
                callback: (target, index) => {
                    found = true;
                    data.call(target, index);
                }
            });
            !found && data.call(null, 0, value);
            return org;

        case "deleteOne":
            if (!org[data.keyArray]) return org;
            LinearSearch({
                org: org[data.keyArray],
                target: data.target,
                start: 0,
                end: org[data.keyArray].length,
                key: data.key,
                callback: (target, index) => {
                    org[data.keyArray].splice(index, 1);
                }
            });
            return org;

        case "deletesOne":
            data?.keysArray?.forEach((keyArray) => {
                let Key = org[keyArray];
                Key && LinearSearch({
                    org: Key,
                    target: data.target,
                    start: 0,
                    end: Key.length,
                    key: data.key,
                    callback: (target, index) => {
                        Key.splice(index, 1);
                    }
                });
            });
            return org;

        case "updatesWithCall":
            data?.keysArray?.forEach((keyArray) => {
                let Key = org[keyArray];
                Key && LinearSearch({
                    org: Key,
                    target: data.target,
                    start: 0,
                    end: Key.length,
                    key: data.key,
                    callback: (target, index) => {
                        data.call(target, index);
                    }
                });
            });
            return org;

        case "updateAllWithCall":
            Object.values(org)?.forEach((value) => {
                LinearSearch({
                    org: value,
                    target: data.target,
                    start: 0,
                    end: value.length,
                    key: data.key,
                    callback: (target, index) => {
                        data.call(target, index);
                    }
                });
            });
            return org;

        case "delete":
            delete org[data.key];
            return org;

        default:
            break;
    }
}
///////////////////////////////////////////////////////////
export const setMessages = (type, data, org) => {
    org = Object.assign([], org);
    switch (type) {
        case "set":
            console.log("🚀  data", data)
            return data;

        case "update":
            org?.unshift(data);
            return org;

        case "add":
            org?.push(data);
            return org;

        case "push":
            return [...org, ...data];

        case "delete":
            LinearSearch({
                org: org,
                target: data?.target,
                start: 0,
                end: org?.length,
                key: data?.key,
                callback: (target, index) => {
                    org?.splice(index, 1);
                }
            });
            return org;

        case "replace":
            LinearSearch({
                org: org,
                target: data?.target,
                start: 0,
                end: org?.length,
                key: data?.key,
                callback: (target, index) => {
                    org?.splice(index, 1);
                }
            });
            org?.unshift(data.replace);
            return org;

        case "updateAll":
            org.forEach((item) => {
                data.call(item);
            })
            return org;

        case "updateAttr":
            LinearSearch({
                org: org,
                target: data?.target,
                start: 0,
                end: org?.length,
                key: data?.key,
                callback: (target, index) => {
                    data?.call(target, index);
                }
            });
            return org;

        default:
            break;
    }
}
///////////////////////////////////////////////////////////
export const setGetData = (type, data, org) => {
    switch (type) {
        case "set":
            return data;

        case "update":
            return org + data;

        default:
            break;
    }
}
//////////////////////////////////////////////////////////////
export const getDataForLang = (lang) => {
    let Data = require(`../lang/${lang}.json`);
    return Data;
}
